import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async downloadExcel(filter) {
        const response = await httpService.downloadExcelWithFilter('Reports/ReportInvoiceDetailExcel', filter);
        return response;
    },
    async getList(filter) 
    {
        const response = await httpService.get(`Reports/ReportInvoiceDetail`, filter);
        
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status) 
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for (let i = 0; i < response.data.data.length; i++) 
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },
    
    default() {
        return {
            id: 0,
            soNumber: null,
            shipToId: 0,
            shipToCode: null,
            shipToName: null,
            locationId: 0,
            locationCode: null,
            locationName: null,
            deliveryDate: null,
            qtyInSalesUnit: 10,
            unitId: 0,
            unitCode: null,
            unitDescription: null,
            priceInSalesUnit: 0,
            soAmountInUSD: 0,
            invoiceList: null,
            invoiceListDate: null,
            invoiceDate: null,
            hasMappingPrice: true
        };
    },

    _toObject(jsonData) {
        const result = this.default();
        result.id = jsonData.id;
        result.soNumber = jsonData.soNumber;
        result.shipToId = jsonData.shipToId;
        result.shipToCode = jsonData.shipToCode;
        result.shipToName = jsonData.shipToName;
        result.locationId = jsonData.locationId;
        result.locationCode = jsonData.locationCode;
        result.locationName = jsonData.locationName;
        result.deliveryDate = jsonData.deliveryDate;
        result.qtyInSalesUnit = jsonData.qtyInSalesUnit;
        result.unitId = jsonData.unitId;
        result.unitCode = jsonData.unitCode;
        result.unitDescription = jsonData.unitDescription;
        result.priceInSalesUnit = jsonData.priceInSalesUnit;
        result.soAmountInUSD = jsonData.soAmountInUSD;
        result.invoiceList = jsonData.invoiceList;
        result.invoiceListDate = jsonData.invoiceListDate;
        result.invoiceDate = jsonData.invoiceDate;
        result.hasMappingPrice = jsonData.hasMappingPrice;
        
        return result;
    }
});