<template>
    <router-view></router-view>

    <bs-search-form>
        <template v-slot:title>Invoice Detail</template>
        <template v-slot:content>
            <div class="col-lg-6 col-sm-12">
                <div class="row mb-3">
                    <label for="filterCustomer" class="col-sm-4 col-form-label">Sold To</label>
                    <div class="col-sm-8">
                        <customer-combo v-model="filter.shipToIdEquals" />
                    </div>
                </div>

                <div class="row mb-3">
                    <label for="filterCustomer" class="col-sm-4 col-form-label">Delivery Date</label>
                    <div class="col-sm-4">
                        <bs-date-picker v-model="filter.deliveryDateFrom" />
                    </div>
                    <div class="col-sm-4">
                        <bs-date-picker v-model="filter.deliveryDateUntil" />
                    </div>
                </div>
            </div>

            <button class="btn btn-primary btn-sm" @click="loadData()"><i class="bi bi-search"></i> Search</button>
            <button class="btn btn-primary btn-sm ms-1" @click="downloadExcel">
                <i class="bi bi-file-earmark-excel"></i> Download Excel
            </button>
        </template>
    </bs-search-form>

    <div class="row justify-content-between mt-3">
        <div class="col-md-6">
            
        </div>
        <div class="col-md-6">
            <div class="pagination-right">
                <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow" @toPage="toPage" />
            </div>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table table-stripped">
            <thead>
                <tr>
                    <th>SO Number</th>
                    <th>Ship To</th>
                    <th>Location</th>
                    <th>Delivery Date</th>
                    <th>Quantity in Sales Unit</th>
                    <th>Sales Unit</th>
                    <th>Price in Sales Unit</th>
                    <th>SO Amount in USD</th>
                    <th>Invoice List</th>
                    <th>Invoice List Date</th>
                </tr>
            </thead>
            <tbody>
                <template v-if="data != null && data.length > 0">
                    <tr v-for="item in data" :key="item.id" :class="item.hasMappingPrice ? '' : 'table-danger'">
                        <td>{{item.soNumber}}</td>
                        <td>{{item.shipToCode}}</td>
                        <td>{{item.locationCode}}</td>
                        <td><view-date v-model="item.deliveryDate" /></td>
                        <td><bs-plain-number v-model="item.qtyInSalesUnit" /></td>
                        <td>{{item.unitCode}}</td>
                        <td><bs-plain-number v-model="item.priceInSalesUnit" /></td>
                        <td><bs-plain-number v-model="item.soAmountInUSD" /></td>
                        <td>{{item.invoiceList}}</td>
                        <td><view-date v-model="item.invoiceListDate" /></td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import ReportInvoiceDetailService from "@/services/report-invoice-detail-service";
import BsPlainNumber from '../../../plugins/components/BsPlainNumber.vue';

export default {
  components: { BsPlainNumber },
    data: function () {
        let currenctDate = new Date();
        return {
            filter: {
                shipToIdEquals: "",
                deliveryDateFrom: new Date(currenctDate.getFullYear(), currenctDate.getMonth(), 1),
                deliveryDateUntil: new Date(currenctDate.getFullYear(), currenctDate.getMonth() + 1, 0)
            },
            errorMessages: null,
            pageNumber: 1,
            perPage: 10,
            totalPage: 100,
            totalRow: 0,
            data: Array(),
        }
    },
    watch: {
        $route: function() {
            if (this.$route.path == "/report-invoice-detail") {
                this.loadData();
            }
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async toPage(pageNumber) {
            this.filter.page = pageNumber;
            this.loadData();
        },
        async loadData() {
            try {
                const result = await ReportInvoiceDetailService.getList(this.filter);
                
                const data = result.data;

                this.pageNumber = data.pageNumber;
                this.perPage = data.perPage;
                this.totalPage = data.totalPage;
                this.totalRow = data.totalRow;
                this.data = data.data;
            } 
            catch (e) {
                this.pageNumber = 0;
                this.perPage = 0;
                this.totalPage = 0;
                this.totalRow = 0;
                this.data = Array();
            }
        },
        async downloadExcel() {
          try {
            this.$store.commit('progressCounterQueueIncrement');
            await ReportInvoiceDetailService.downloadExcel(this.filter);
          }
          finally {
            this.$store.commit('progressCounterQueueDecrement');
          }
        }
    }
}
</script>

<style scoped>
    th, td {
        white-space: nowrap;
    }
</style>